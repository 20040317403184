import React, { useEffect, useState, useContext } from 'react'
import './AllPost.css'
import Header from '../../components/Header/Header'
import { getDocs, getFirestore, collection, query, orderBy } from "firebase/firestore";
import { FirebaseContext } from '../../Context'
import { useNavigate } from 'react-router-dom'
import Footer from '../../components/Footer/Footer';



function AllPost() {
    const [postData, setPostData] = useState([]);
    const [sellerData, setSellerData] = useState([]);
    const { firebase } = useContext(FirebaseContext);
    const db = getFirestore(firebase);


    const navigate = useNavigate()




    useEffect(() => {
        async function fetchPostData() {
            try {
                const q = await query(collection(db, "posts"), orderBy("date", "desc"));

                try {
                    const querySnapshot = await getDocs(q);
                    const allPosts = [];

                    querySnapshot.forEach((doc) => {
                        // doc.data() is never undefined for query doc snapshots
                        //console.log(doc.id, " => ", doc.data());
                        allPosts.push({
                            ...doc.data(),
                            id: doc.id
                        });
                    });

                    setPostData(allPosts);
                    //console.log(allPosts);
                } catch (error) {
                    //console.log("Error getting posts: ", error);
                }
            } catch (e) {
                //console.log(e);
            }
        }


        async function fetchSellerData() {
            try {
                await getDocs(collection(db, "sellers")).then((snapshot) => {
                    const allSellers = snapshot.docs.map((sellers) => {
                        return {
                            ...sellers.data(),
                            id: sellers.id
                        }
                    })
                    setSellerData(allSellers)
                    //console.log(allSellers);
                })
            } catch (e) {
                //console.log(e);
            }
        }

        fetchPostData();
        fetchSellerData();

    }, [db])




    return (
        <div className='AllPost'>
            <Header />
            <p className='all-post-heading'>All Post</p>
            <div className='all-Post'>

                {postData.map((post) => {
                    var firestoreDate = post.date;
                    var jsDate = firestoreDate.toDate();
                    var options = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
                    var formattedDate = jsDate.toLocaleDateString('en-US', options);
                    //console.log(formattedDate);
                    var currentDate = new Date();
                    var isToday = jsDate.toDateString() === currentDate.toDateString();
                    var isYesterday = new Date(jsDate.getFullYear(), jsDate.getMonth(), jsDate.getDate() + 1).toDateString() === currentDate.toDateString();
                    var displayDate = isToday ? 'Today' : (isYesterday ? 'Yesterday' : formattedDate);
                    return (
                        <div class="card"
                            onClick={(() => {
                                const postId = post.id;
                                navigate(`/view-post/${postId}`);
                            })}
                        >
                            <img
                                className={`latest-img ${post.status === false ? 'height-90' : 'height-110'}`}
                                src={post.images}
                                alt="Avatar"
                            />
                            {post.status === false ? <span className='item-sold'>Item Sold</span> : ''}
                            <div class="container">
                                <h2>₹ {post.price}</h2>
                                <hr />
                                <h4><b>{post.productName}</b></h4>
                                <p>{post.model}</p>
                                <div className='seller-date'>
                                    <p>{displayDate}</p>
                                    {sellerData.map((seller) => {
                                        return (
                                            <p>{post.userId === seller.userId ? seller.sellerName : ''}</p>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    )
                })}

            </div>
            <Footer />
        </div>
    )
}

export default AllPost