import React from 'react'
import './SoldPost.css'



function SoldPost(props) {
    const soldPosts = props.soldPosts;
    const allSellers = props.allSellers;

    return (
        <div className='SoldPost'>
            <div className='admin-sold-post'>
                <h2>Sold Post</h2>
                <div className='admin-sold-post-show'>

                    {soldPosts.map((post) => {
                        var firestoreDate = post.date;
                        var jsDate = firestoreDate.toDate();
                        var options = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
                        var formattedDate = jsDate.toLocaleDateString('en-US', options);
                        //console.log(formattedDate);
                        var currentDate = new Date();
                        var isToday = jsDate.toDateString() === currentDate.toDateString();
                        var isYesterday = new Date(jsDate.getFullYear(), jsDate.getMonth(), jsDate.getDate() + 1).toDateString() === currentDate.toDateString();
                        var displayDate = isToday ? 'Today' : (isYesterday ? 'Yesterday' : formattedDate);
                        return (
                            <div class="card">
                                <img className='admin-sold-post-img' src={post.images} alt="Avatar" />
                                <div class="container">
                                    <h2>₹ {post.price}</h2>
                                    <hr />
                                    <h4><b>{post.productName}</b></h4>
                                    <p>{post.model}</p>
                                    <div className='seller-date'>
                                        <p>{displayDate}</p>
                                        {allSellers.map((seller) => {
                                            return (
                                                <p>{post.userId === seller.userId ? seller.sellerName : ''}</p>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>
            </div>
        </div>
    )
}

export default SoldPost