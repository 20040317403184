import React from 'react'
import './BlacklistedSellers.css'

function BlacklistedSellers(props) {
    const blacklistedSellers = props.blacklistedSellers;
    console.log(blacklistedSellers);

    return (
        <div className='BlacklistedSellers'>
            <div className='admin-blacklisted-sellers'>
                <h2>Blacklisted Sellers</h2>
                {blacklistedSellers
                    ? <table id="sellers">
                        <tr>
                            <th>Name</th>
                            <th>Place</th>
                            <th>WhatsApp</th>
                        </tr>
                        <tr>
                            <td>Alfreds Futterkiste</td>
                            <td>Maria Anders</td>
                            <td>Germany</td>
                        </tr>
                    </table>
                    : <span>No Blacklisted Sellers</span>
                }
            </div>
        </div>
    )
}

export default BlacklistedSellers