import React, { useContext } from 'react'
import './LargeDevice.css'
import { useState } from 'react'
import { getStorage, ref, uploadBytesResumable, deleteObject, getDownloadURL } from "firebase/storage";
import { doc, setDoc, getFirestore, getDoc } from "firebase/firestore";
import { FirebaseContext } from '../../../Context'
import ProgressBar from "@ramonak/react-progress-bar";


function LargeDevice() {
    const [selectedImage, setSelectedImage] = useState("")
    const [image, setImage] = useState("")
    const [message, setMessage] = useState(false)
    const [messageError, setErrorMessage] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploading, setUploading] = useState(false);


    const { firebase } = useContext(FirebaseContext);
    const db = getFirestore(firebase);

    const onSelectFile = (event) => {
        const file = event.target.files[0];
        //console.log(file);
        setSelectedImage(URL.createObjectURL(file));
        setImage(file);
    };

    const handleSubmit = async () => {
        const storage = getStorage();
        setUploading(true);
        setUploadProgress(0);
        var imageName = '';

        const docRef = doc(db, "banner", "large_device");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            imageName = docSnap.data().title;
        } else {
            console.log("No such document!");
        }

        const bannerImageRef = ref(storage, `Banner/${imageName}`);

        deleteObject(bannerImageRef)
            .then(() => {
                const newImageRef = ref(storage, `Banner/${image.name}`);

                // Add a progress event listener to track the upload progress
                const uploadTask = uploadBytesResumable(newImageRef, image);
                uploadTask.on('state_changed', (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    const progressValue = Math.round(progress); // Limit progress value to maximum 100
                    setUploadProgress(progressValue);
                });

                return uploadTask;
            })
            .then((snapshot) => {
                getDownloadURL(snapshot.ref).then(async (url) => {
                    await setDoc(doc(db, "banner", "large_device"), {
                        title: image.name,
                        image: url
                    });
                });

                console.log('Uploaded a blob or file!');
                setSelectedImage('');
                setUploading(false);
                setUploadProgress(0);
                setMessage(true);
                setTimeout(() => {
                    setMessage(false);
                }, 5000);
            })
            .catch((error) => {
                console.log('Error:', error);
                setUploading(false);
                setErrorMessage(true);
                setTimeout(() => {
                    setErrorMessage(false);
                }, 5000);
            });
    };



    return (
        <div className='LargeDevice'>
            <div className='large-device'>
                <h2>Large Device</h2>
                {message && <span className='upload-success-message'>Uploaded Sucessfully</span>}
                {messageError && <span className='upload-error-message'>Please Select Image</span>}
                <h4>Size : 1920px X 350px</h4>
                <label className='img-container'>
                    <i class="fa-solid fa-camera"></i>
                    <input
                        type="file"
                        name="image"
                        id="image-input"
                        key={Date.now()}
                        onChange={onSelectFile}
                        accept='image/png, image/jpeg, image/webp'
                    />
                    <p>Add Image</p>
                </label>
                <div key={selectedImage} className='thumbnail'>
                    {selectedImage && <img src={selectedImage} alt='thumbnail' />}
                    {selectedImage && <div className='delete-btn'>
                        <p>Remove</p>
                        <i
                            class="fa-sharp fa-solid fa-trash"
                            onClick={() => {
                                setSelectedImage(null);
                                setImage(null);
                                // Reset the input element by assigning a new key
                                document.getElementById("image-input").key = Date.now();
                            }}
                        >

                        </i>
                    </div>}
                </div>
                <div className='progressbar'>
                    {uploading ? <ProgressBar completed={uploadProgress} /> : ''}
                </div>
                <button onClick={handleSubmit}>Save</button>
            </div>
        </div>
    )
}

export default LargeDevice