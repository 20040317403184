import React, { useContext, useState, useEffect } from 'react'
import './SellerPost.css'
import { useNavigate } from 'react-router-dom'
import { doc, deleteDoc, getFirestore, updateDoc } from "firebase/firestore";
import { FirebaseContext } from '../../Context'
import { getStorage, ref, deleteObject } from "firebase/storage";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';


function SellerPost(props) {
    const { seller, posts } = props;
    const { firebase } = useContext(FirebaseContext)
    const navigate = useNavigate()

    const db = getFirestore(firebase);
    const storage = getStorage();

    const [currentPosts, setCurrentPosts] = useState([]);

    useEffect(() => {
        setCurrentPosts(posts);
    }, [posts]);


    const handleBtnSold = async (e, post) => {
        e.stopPropagation();
        const washingtonRef = doc(db, "posts", post.id);

        // Set the "capital" field of the city 'DC'
        await updateDoc(washingtonRef, {
            status: false
        });

        // Add your logic to mark the post as sold
    };

    const handleBtnDelete = async (e, post) => {
        e.stopPropagation();

        post.images.forEach((imagePath) => {
            // Create a reference to the file to delete
            const imageRef = ref(storage, imagePath);

            // Delete the file
            deleteObject(imageRef)
                .then(() => {
                    //console.log(`Deleted ${imagePath}`);
                })
                .catch((error) => {
                    //console.error(`Error deleting ${imagePath}:`, error);
                });
        });

        await deleteDoc(doc(db, "posts", post.id));
        //console.log('deleted');
        setCurrentPosts(currentPosts.filter((p) => p.id !== post.id));
    };

    return (
        <div className='All-post'>
            <div className='seller-all-post'>

                {currentPosts.map((post) => {
                    var firestoreDate = post.date;
                    var jsDate = firestoreDate.toDate();
                    var options = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
                    var formattedDate = jsDate.toLocaleDateString('en-US', options);
                    //console.log(formattedDate);
                    var currentDate = new Date();
                    var isToday = jsDate.toDateString() === currentDate.toDateString();
                    var isYesterday = new Date(jsDate.getFullYear(), jsDate.getMonth(), jsDate.getDate() + 1).toDateString() === currentDate.toDateString();
                    var displayDate = isToday ? 'Today' : (isYesterday ? 'Yesterday' : formattedDate);
                    return (
                        <div class="card"
                            key={post.id}
                            onClick={(() => {
                                const postId = post.id;
                                navigate(`/view-post/${postId}`);
                            })}
                        >
                            <img
                                className={`latest-img ${post.status === false ? 'height-90' : 'height-110'}`}
                                src={post.images}
                                alt="Avatar"
                            />
                            {post.status === false ? <span className='item-sold'>SOLD</span> : ''}


                            <div class="container">
                                <h2>₹ {post.price}</h2>
                                <hr />
                                <h4><b>{post.productName}</b></h4>
                                <p>{post.model}</p>
                                <div className='seller-date'>
                                    <p>{displayDate}</p>
                                    <p>{seller.sellerName}</p>
                                </div>
                                <div className='post-action'>
                                    <button onClick={(e) => handleBtnSold(e, post)} className='btn-sold'>Mark as sold</button>
                                    <Popup trigger={<button className='btn-delete'>Delete</button>} position="top right">
                                        {close => (
                                            <div className="modal">
                                                <div> Are you sure...? </div>

                                                <div className="actions">
                                                    <button
                                                        className="yes"
                                                        onClick={(e) => handleBtnDelete(e, post)}
                                                    >
                                                        Yes
                                                    </button>
                                                    <button
                                                        className="no"
                                                        onClick={() => {
                                                            close();
                                                        }}
                                                    >
                                                        No
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </Popup>
                                </div>
                            </div>
                        </div>
                    )
                })}

            </div>
        </div>
    )
}

export default SellerPost