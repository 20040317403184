import React, { createContext, useState } from 'react';


export const FirebaseContext = createContext(null)
export const AuthContext = createContext(null)
export const AdminAuthContext = createContext(null)


export default function Context({ children }) {
    const [user, setUser] = useState(null)
    const [admin, setAdmin] = useState(null);

    return (
        <AuthContext.Provider value={{ user, setUser }}>
            <AdminAuthContext.Provider value={{ admin, setAdmin }}>
                {children}
            </AdminAuthContext.Provider>
        </AuthContext.Provider>
    )
}