import React from 'react'
import './NewUsers.css'



function NewUsers(props) {
    const newUsers = props.newUsers;

    return (
        <div className='NewUsers'>
            <div className='admin-new-users'>
                <h2>New Users</h2>
                <table id="users">
                    <tr>
                        <th>Name</th>
                        <th>Contact</th>
                        <th>Email</th>
                    </tr>
                    {newUsers.map((users) => {
                        return (
                            <tr>
                                <td>{users.fname} {users.lname}</td>
                                <td>{users.phone}</td>
                                <td>{users.email}</td>
                            </tr>
                        )
                    })}
                </table>
            </div>
        </div>
    )
}

export default NewUsers