import React from 'react'
import './ViewPost.css'
import Header from '../../components/Header/Header'
import { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { FirebaseContext } from '../../Context';
import { getFirestore, doc, getDoc } from "firebase/firestore";
import 'firebase/firestore';
import Footer from '../../components/Footer/Footer'


function ViewPost() {
    const [position, setPosition] = useState(0);
    const [sellerData, setSellerData] = useState([])
    const { firebase } = useContext(FirebaseContext)
    const [postData, setPostData] = useState([])
    const [sellerId, setSellerId] = useState('')

    const db = getFirestore(firebase);

    const { postId } = useParams();



    useEffect(() => {
        async function fetchPostData() {
            try {
                const docRef = doc(db, "posts", postId);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setPostData(docSnap.data());
                    setSellerId(docSnap.data().userId)
                } else {
                    // Handle case where the document doesn't exist
                }
            } catch (error) {
                // Handle error fetching post data
            }
        }

        async function fetchSellerData() {
            try {
                const docRef = doc(db, "sellers", sellerId);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setSellerData(docSnap.data());
                    //console.log(docSnap.data());
                } else {
                    // Handle case where the document doesn't exist
                }
            } catch (e) {
                // Handle error fetching seller data
            }
        }

        fetchPostData();
        fetchSellerData();
    }, [db, postId, sellerId]);

    if (!postData || !sellerData) {
        return <div>Loading...</div>;
    }

    //console.log(sellerId);
    //console.log(sellerData);

    // Date Displaying
    let formattedDate = '';
    let isToday = false;
    let isYesterday = false;
    let displayDate = '';

    if (postData && postData.date) {
        const firestoreDate = postData.date;
        const jsDate = firestoreDate.toDate();
        const options = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
        formattedDate = jsDate.toLocaleDateString('en-US', options);

        const currentDate = new Date();
        isToday = jsDate.toDateString() === currentDate.toDateString();
        isYesterday = new Date(jsDate.getFullYear(), jsDate.getMonth(), jsDate.getDate() + 1).toDateString() === currentDate.toDateString();
        displayDate = isToday ? 'Today' : (isYesterday ? 'Yesterday' : formattedDate);
    }

    // console.log("Formatted Date:", formattedDate);
    // console.log("Is Today:", isToday);
    // console.log("Is Yesterday:", isYesterday);
    // console.log("Display Date:", displayDate);

    const images = [];

    if (postData && postData.images) {
        postData.images.map((image) => {
            return (
                images.push(image)
            )
        })
    }

    const nextImage = () => {
        setPosition(prevPosition => (prevPosition + 1) % images.length);
    };

    const prevImage = () => {
        setPosition(prevPosition =>
            prevPosition === 0 ? images.length - 1 : prevPosition - 1
        );
    };

    return (
        <div className='ViewPost'>
            <Header />
            {postData && (<div className='post-details'>
                <h2 className='image-number'>{position + 1}/{images.length}</h2>
                <div className='img-carousel'>
                    <div id='images' className='wrapper'>
                        <img
                            src={images[position]}
                            alt={{position}}
                            id='img'
                        />
                    </div>
                    {images.length > 1
                        ? <div className='buttons'>
                            <button id='prev' onClick={prevImage}>
                                <i class="fa-solid fa-angles-left"></i>
                            </button>
                            <button id='next' onClick={nextImage}>
                                <i class="fa-solid fa-angles-right"></i>
                            </button>
                        </div>
                        : ''}
                </div>

                <div className='vehicle-details'>
                    <span className='vehicle-price'>₹ {postData.price}</span>
                    <h3 className={`post-status ${postData.status !== true ? 'sold' : 'active'}`}>{postData.status !== true ? 'Sold' : 'Active'}</h3>
                    <h3>{postData.productName}</h3>
                    <h3>{postData.model}</h3>
                    <hr />
                    {sellerData && <div>
                        <h3 className='sellername'>{sellerData.sellerName}</h3>
                        <h3 className='place'>{sellerData.place}</h3>
                    </div>
                    }
                    <h3 className='post-date'>{displayDate}</h3>
                </div>

                <div className='post-description'>
                    <h2>Description</h2>
                    <textarea className='description' value={postData.description}></textarea>
                    <button className='whatsapp-chat-button'>
                        <i class="fa-brands fa-whatsapp" id='whatsapp-icon'></i>
                        {sellerData && (
                            <a href={`https://api.whatsapp.com/send?phone=91${sellerData.whatsappNumber}
                             &text=${encodeURIComponent(`Is it available?\n\nProduct: ${postData.productName}\nPrice: ₹ ${postData.price}\nModel: ${postData.model}`)}`}
                                className='whatsapp-chat'>Chat</a>
                        )}
                    </button>
                </div>
            </div>)}
            <Footer />
        </div>
    )
}

export default ViewPost