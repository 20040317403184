import firebase from "firebase/compat/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCc2IF6dtyGVD1ud1txbGysZmf_9dHc7Bg",
  authDomain: "second-choice-65ac1.firebaseapp.com",
  projectId: "second-choice-65ac1",
  storageBucket: "second-choice-65ac1.appspot.com",
  messagingSenderId: "1091503229500",
  appId: "1:1091503229500:web:9587bba4ba7b11eac21139",
  measurementId: "G-JY9PH37P57"
};

// Initialize Firebase
export default firebase.initializeApp(firebaseConfig);