import React, { useContext, useEffect } from 'react'
import Header from '../../components/Header/Header'
import { useState } from 'react'
import SellerPost from '../../components/SellerPost/SellerPost'
import { AuthContext, FirebaseContext } from '../../Context'
import { updateProfile } from 'firebase/auth';
import { getDoc, getFirestore, setDoc, doc, addDoc, collection, Timestamp, query, where, getDocs } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom'
import ProgressBar from "@ramonak/react-progress-bar";
import './Profile.css'



function Profile() {
    const [component, setComponent] = useState("");
    const [fname, setFName] = useState('');
    const [lname, setLName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');

    const [userData, setUserData] = useState([]);
    const [postData, setPostData] = useState([]);
    const [sellerData, setSellerData] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [updateProfileSuccess, setUpdateProfileSuccess] = useState(false);
    const [uploadPostSuccess, setUploadPostSuccess] = useState(false);

    const [sellerName, setSellerName] = useState('');
    const [place, setPlace] = useState('');
    const [whatsappNumber, setWhatsappNumber] = useState('');
    const [instaURL, setInstaURL] = useState('');
    const [updateSellerSuccess, setUpdateSellerSuccess] = useState(false);

    const [productName, setProductName] = useState('');
    const [price, setPrice] = useState('');
    const [model, setModel] = useState('');
    const [description, setDescription] = useState('');
    const [images, setImages] = useState([]);

    const navigate = useNavigate();

    const { user } = useContext(AuthContext);
    const { firebase } = useContext(FirebaseContext);
    const db = getFirestore(firebase);
    const currentDate = new Date();



    useEffect(() => {
        async function fetchUserData() {
            try {
                const docRef = doc(db, 'users', user.uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    //console.log("Document data:", docSnap.data());
                    setUserData(docSnap.data())
                    setFName(docSnap.data().fname || '');
                    setLName(docSnap.data().lname || '');
                    setPhone(docSnap.data().phone || '');
                    setEmail(docSnap.data().email || '');
                } else {
                    // docSnap.data() will be undefined in this case
                    console.log("No such document!");
                }
            } catch (e) {
                console.log(e);
            }
        }



        async function fetchSellerData() {
            try {
                const docRef = doc(db, 'sellers', user.uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    //console.log("Document data:", docSnap.data());
                    setSellerName(docSnap.data().sellerName || '');
                    setPlace(docSnap.data().place || '');
                    setWhatsappNumber(docSnap.data().whatsappNumber || '');
                    setInstaURL(docSnap.data().instaURL || '');
                    setSellerData(docSnap.data())
                } else {
                    // docSnap.data() will be undefined in this case
                    console.log("No such document!");
                }
            } catch (e) {
                console.log(e);
            }
        }

        async function fetchPostData() {
            try {
                const q = await query(collection(db, "posts"), where("userId", "==", user.uid));

                try {
                    const querySnapshot = await getDocs(q);
                    const allPosts = [];

                    querySnapshot.forEach((doc) => {
                        // doc.data() is never undefined for query doc snapshots
                        //console.log(doc.id, " => ", doc.data());
                        allPosts.push({
                            ...doc.data(),
                            id: doc.id
                        });
                    });

                    setPostData(allPosts);
                    //console.log(allPosts);
                } catch (error) {
                    console.log("Error getting posts: ", error);
                }
            } catch (e) {
                console.log(e);
            }
        }


        fetchUserData();
        fetchSellerData();
        fetchPostData();
    }, [db, user])


    //MULTIPLE IMAGE SELECTION 
    const onSelectFile = (event) => {
        const selectedFiles = event.target.files;
        //console.log(selectedFiles);

        const selectedFilesArray = Array.from(selectedFiles);
        //console.log(selectedFilesArray);

        const imagesArray = selectedFilesArray.map((file) => {
            return URL.createObjectURL(file);
        })

        setSelectedImages((previousImages) => previousImages.concat(imagesArray))
        setImages(selectedFilesArray)
    }


    const updateUserProfile = (e) => {
        e.preventDefault()
        // console.log(user.uid);
        updateProfile(user, { displayName: lname }).then(async () => {
            try {
                await setDoc(doc(db, 'users', user.uid), {
                    id: user.uid,
                    fname: fname,
                    lname: lname,
                    phone: phone,
                    email: email,
                    date: currentDate
                });
                //console.log("Document written with ID: ", docRef.id);

            } catch (e) {
                console.error("Error adding document: ", e);
            }
        }).then(() => {
            //....
            setUpdateProfileSuccess(true);
            setTimeout(() => {
                setUpdateProfileSuccess(false);
            }, 5000);
        })
    }


    //SELLER ACCOUNT DATA 
    const submitSellerAccountData = async (e) => {
        e.preventDefault()
        //console.log(user.uid);
        try {
            await setDoc(doc(db, 'sellers', user.uid), {
                userId: user.uid,
                sellerName: sellerName,
                place: place,
                whatsappNumber: whatsappNumber,
                instaURL: instaURL,
                date: currentDate
            }).then(() => {
                setUpdateSellerSuccess(true);
                setTimeout(() => {
                    setUpdateSellerSuccess(false);
                }, 5000);
            })
            //console.log("Document written with ID: ", docRef.id);

        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }



    //UPLOAD POST
    const uploadPost = async (e) => {
        e.preventDefault();

        setUploading(true);
        setUploadProgress(0);

        const storage = getStorage();
        const downloadURLs = [];
        var date = Timestamp.fromDate(new Date());


        const uploadTasks = images.map((image) => {
            const storageRef = ref(storage, `images/${image.name}`);
            const uploadTask = uploadBytesResumable(storageRef, image);

            return new Promise((resolve, reject) => {
                uploadTask.on(
                    'state_changed',
                    (snapshot) => {
                        // Handle progress or state changes
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        const progressValue = Math.round(progress); // Limit progress value to maximum 100
                        setUploadProgress(progressValue);
                    },
                    (error) => {
                        // Handle errors
                        console.error('Upload error:', error);
                        reject(error);
                    },
                    () => {
                        // Handle successful upload
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            image.downloadURL = downloadURL;
                            //console.log(image.downloadURL);
                            downloadURLs.push(image.downloadURL);
                            resolve();
                        }).catch((error) => {
                            console.error('Download URL error:', error);
                            reject(error);
                        });
                        //console.log('Upload complete!');
                    }
                );
            });
        });

        await Promise.all(uploadTasks)
            .then(async () => {
                try {
                    await addDoc(collection(db, 'posts'), {
                        userId: user.uid,
                        productName: productName,
                        price: price,
                        model: model,
                        description: description,
                        images: downloadURLs,
                        date: date,
                        status: true
                    });
                    //console.log("Document written with ID: ", docRef.id);

                } catch (e) {
                    console.error("Error adding document: ", e);
                }
                // All upload tasks completed
                setSelectedImages([]);
                setUploading(false);
                setUploadProgress(0);
                setProductName('')
                setPrice('')
                setModel('')
                setDescription('')
                setUploadPostSuccess(true)
                setTimeout(() => {
                    setUploadPostSuccess(false);
                }, 5000);
            })
            .catch((error) => {
                // Error occurred during the uploads
                console.error('Upload error:', error);
                // setUploading(false);
                // setUploadProgress(0);
            });
    }



    return (
        <div className='Profile'>
            <Header />
            <div className='profile-page'>
                <div className='profile-body'>
                    <div className='profile-details'>
                        <div className='personal-information'>
                            <div className='profile-box'>
                                <img src="\images\profile.png"
                                    alt="" className='profile-avatar' />
                            </div>
                            <div className='personal-items'>
                                <p>Hello,</p>
                                <span>{user ? userData.fname + ' ' + userData.lname : 'Welcome'}</span>
                            </div>
                        </div>
                    </div>
                    <div className='side-menu'>
                        <div className='account-information'>
                            <div className='sub-heading'>
                                <i class="fa-solid fa-user"></i>
                                <h2>Account Settings</h2>
                            </div>
                            <div className='personal-items'>
                                <div className='div-button' onClick={() => setComponent("profile-information")}>Profile Information</div>
                                <div className='div-button' onClick={() => setComponent("seller-account")}>Seller Account</div>
                                <div className='div-button' onClick={() => setComponent("subscription")}>My Subscription</div>
                            </div>
                        </div>
                        <hr />
                        <div className='account-information'>
                            <div className='sub-heading'>
                                <i class="fa-sharp fa-solid fa-plus"></i>
                                <h2>My Posts</h2>
                            </div>
                            <div className='personal-items'>
                                <div className='div-button' onClick={() => setComponent("all-post")}>All Posts</div>
                                <div className='div-button' onClick={() => setComponent("new-post")}>New Post</div>
                            </div>
                        </div>
                        <hr />
                        <div className='account-information'>
                            <div className='sub-heading'>
                                <i class="fa-sharp fa-solid fa-power-off"></i>
                                <h2 onClick={() => {
                                    const auth = getAuth();
                                    signOut(auth).then(() => {
                                        // Sign-out successful.
                                        navigate('/')
                                    }).catch((error) => {
                                        // An error happened.
                                    });
                                }}>Logout</h2>
                            </div>
                        </div>
                    </div>
                </div>





                {/* PROFILE INFORMATION */}
                {component === "profile-information" &&
                    <div className='personal-information-details'>
                        <p>Profile Information</p>
                        {updateProfileSuccess && <span className='sucess-message'>Updated Successfully</span>}
                        <form onSubmit={updateUserProfile}>
                            <div className='full-name'>
                                <input
                                    type="text"
                                    placeholder='First Name'
                                    value={fname}
                                    onChange={(e) => setFName(e.target.value)}
                                    id='fname'
                                />
                                <input
                                    type="text"
                                    placeholder='last Name'
                                    value={lname}
                                    onChange={(e) => setLName(e.target.value)}
                                />
                            </div>
                            <div className='mobile'>
                                <label htmlFor="mobile">Mobile Number</label>
                                <input
                                    type="number"
                                    placeholder='Mobile'
                                    id="phone"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </div>
                            <div className='email'>
                                <label htmlFor="email">Email Address</label>
                                <input
                                    type="email"
                                    placeholder='Email'
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className='update-btn'>
                                <button>Save</button>
                            </div>
                        </form>
                    </div>
                }





                {/* SELLER ACCOUNT */}
                {component === "seller-account" &&
                    <div className='seller-account-details'>
                        <p>Seller Account</p>
                        {updateSellerSuccess && <span className='sucess-message'>Updated Successfully</span>}
                        <form onSubmit={submitSellerAccountData}>
                            <div className='seller-name'>
                                <label htmlFor="seller-name">Name</label>
                                <input
                                    type="text"
                                    value={sellerName}
                                    onChange={(e) => setSellerName(e.target.value)}
                                    id='sellername'
                                    placeholder='Seller or Shop Name'
                                />
                            </div>
                            <div className='seller-place'>
                                <label htmlFor="seller-place">Place </label>
                                <input
                                    type="text"
                                    value={place}
                                    onChange={(e) => setPlace(e.target.value)}
                                    id='place'
                                    placeholder='Place'
                                />
                            </div>
                            <div className='whatsapp-number'>
                                <label htmlFor="whatsapp-number">Whatsapp Number</label>
                                <input
                                    type="number"
                                    value={whatsappNumber}
                                    onChange={(e) => setWhatsappNumber(e.target.value)}
                                    id='whatsappnumber'
                                    placeholder='Whatsapp Number'
                                />
                            </div>
                            <div className='insta-url'>
                                <label htmlFor="insta-url">Instagram URL</label>
                                <input
                                    type="text"
                                    value={instaURL}
                                    onChange={(e) => setInstaURL(e.target.value)}
                                    id='instaurl'
                                    placeholder='Insta URL'
                                />
                            </div>
                            <div className='update-btn'>
                                <button>Save</button>
                            </div>
                        </form>
                    </div>
                }





                {/* SUBSCRIPTION */}
                {component === "subscription" &&
                    <div className='subscription-details'>
                        <p>My Subscription</p>
                        <div className='current-subscription'>
                            <label htmlFor="current-subscription">Current Subscription</label>
                            <p>Free Trial</p>
                        </div>
                        {/* <div className='subscription-amount'>
                            <label htmlFor="subscription-amount">Subscription Amount</label>
                            <label htmlFor="amount">₹ 1499</label>
                        </div>
                        <div className='subscription-payment'>
                            <label htmlFor="subscription-payment">Subscription Payment</label>
                            <button>Pay Now</button>
                        </div> */}
                    </div>
                }



                {/* ALL POST */}
                {component === "all-post" &&
                    <div className='all-post-details'>
                        <p>All Post</p>
                        <div className='seller-post'>
                            <SellerPost seller={sellerData} posts={postData} />
                        </div>
                    </div>
                }



                {/* NEW POST */}
                {component === "new-post" &&
                    <div className='new-post-details'>
                        <p>New Post</p>
                        {uploadPostSuccess && <span className='sucess-message'>Uploaded Successfully</span>}
                        <form onSubmit={uploadPost} disabled={uploading || selectedImages.length === 0}>
                            <div className='post-name'>
                                <label htmlFor="post-name">Name</label>
                                <input
                                    type="text"
                                    placeholder='Name'
                                    value={productName}
                                    onChange={(e) => setProductName(e.target.value)}
                                />
                            </div>
                            <div className='price'>
                                <label htmlFor="price">Price</label>
                                <input
                                    type="text"
                                    placeholder='Price'
                                    value={price}
                                    onChange={(e) => setPrice(e.target.value)}
                                />
                            </div>
                            <div className='model'>
                                <label htmlFor="model">Model</label>
                                <input
                                    type="text"
                                    placeholder='Model'
                                    value={model}
                                    onChange={(e) => setModel(e.target.value)}
                                />
                            </div>
                            <div className='description'>
                                <label htmlFor="description">Description</label>
                                <textarea
                                    name=""
                                    id=""
                                    cols="50"
                                    rows="12"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder='Description'
                                ></textarea>
                            </div>
                            <div className='image-label'>
                                <label htmlFor="image-label">Upload upto 10 Photos</label>
                            </div>
                            <div className='image'>
                                <label>
                                    <i class="fa-solid fa-camera"></i>
                                    Add Photo
                                    <input
                                        type="file"
                                        name="images"
                                        onChange={onSelectFile}
                                        multiple
                                        accept='image/png, image/jpeg, image/webp'
                                    />
                                </label>
                            </div>
                            <div className='display-image'>
                                {selectedImages && selectedImages.map((image, Index) => {
                                    return (
                                        <div key={image} className='image-box'>
                                            <img src={image} alt='' />
                                            <button
                                                onClick={() => {
                                                    setSelectedImages(selectedImages.filter((e) => e !== image))
                                                    console.log(images);
                                                }}
                                            >
                                                <i class="fa-solid fa-x"></i>
                                            </button>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='progressbar'>
                                {uploading ? <ProgressBar completed={uploadProgress} /> : ''}
                            </div>
                            <div className='submit-btn'>
                                <button>Upload</button>
                            </div>
                        </form>
                    </div>
                }
            </div >

        </div >
    )
}

export default Profile