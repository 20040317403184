import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Context, { FirebaseContext } from './Context'
import firebase from './config'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <FirebaseContext.Provider value={{ firebase }}>
    <Context>
      {/* <React.StrictMode> */}
        <App />
      {/* </React.StrictMode> */}
    </Context>
  </FirebaseContext.Provider>
);