import React from 'react'
import './NewSellers.css'

function NewSellers(props) {
    const newSellers = props.newSellers;

    return (
        <div className='NewSellers'>
            <div className='admin-new-sellers'>
                <h2>New Sellers</h2>
                <table id="sellers">
                    <tr>
                        <th>Name</th>
                        <th>Place</th>
                        <th>WhatsApp</th>
                    </tr>
                    {newSellers.map((seller) => {
                        return (
                            <tr>
                                <td>{seller.sellerName}</td>
                                <td>{seller.place}</td>
                                <td>{seller.whatsappNumber}</td>
                            </tr>
                        )
                    })}
                </table>
            </div>
        </div>
    )
}

export default NewSellers