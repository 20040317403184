import React from 'react'
import './BlackListedUsers.css'

function BlacklistedUsers(props) {
    const blacklistedUsers = props.BlacklistedUsers;

    return (
        <div className='BlacklistedUsers'>
            <div className='admin-blacklisted-users'>
                <h2>BlackListed Users</h2>
                {blacklistedUsers ? <table id="users">
                    <tr>
                        <th>Name</th>
                        <th>Contact</th>
                        <th>Email</th>
                    </tr>
                    <tr>
                        <td>Alfreds Futterkiste</td>
                        <td>Maria Anders</td>
                        <td>Germany</td>
                    </tr>
                </table> : <span>No Blacklisted Users</span>}
            </div>
        </div>
    )
}

export default BlacklistedUsers