import React, { useContext } from 'react'
import '../Header/Header.css'
import { AuthContext } from '../../Context'
import { useNavigate } from 'react-router-dom'


function Header() {
    const { user } = useContext(AuthContext)
    const navigate = useNavigate()

    const handleSearchIcon = (() => {
        navigate('/search')
    })


    return (
        <div className='header'>
            <div className='container'>
                <nav>
                    <a href="/"><img src="\images\logo.png" alt="" className='logo' /></a>
                    <div className='search-icon-small' onClick={handleSearchIcon}>
                        <i class="fa-solid fa-magnifying-glass"></i>
                    </div>
                    <div className='search-bar' onClick={handleSearchIcon}>
                        <div className='search-label'>
                            <p>Search</p>
                        </div>
                        <div className='search-icon'>
                            <i class="fa-sharp fa-solid fa-magnifying-glass"></i>
                        </div>
                    </div>
                    <ul id='sidemenu'>
                        <li><span className='login'>{user ? '' : <a href='/login'>Login</a>}</span></li>
                        <li>
                            <a href='/account'><i class="fa-solid fa-user"></i></a>
                            <span className='account'>{user ? <a href='/account' className='user-name-display'>{user.displayName}</a> : ''}</span>
                        </li>
                        <i class="fa-solid fa-xmark" onClick={() => {
                            var sideMenu = document.getElementById("sidemenu")
                            sideMenu.style.right = "-200px"
                        }}></i>
                    </ul>
                    <i class="fa-solid fa-bars" onClick={() => {
                        var sideMenu = document.getElementById("sidemenu")
                        sideMenu.style.right = "0"
                    }}></i>
                </nav>
            </div>
        </div>
    )
}

export default Header