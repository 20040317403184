import React from 'react'
import './Login.css'
import OTPInput from "otp-input-react";
import { useState } from 'react';
import { CgSpinner } from 'react-icons/cg'
import PhoneInput from 'react-phone-input-2';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom'




function Login() {
  const history = useNavigate()
  const [otp, setOTP] = useState("")
  const [loading, setLoading] = useState(false)
  const [phone, setPhone] = useState("")
  const [showOtp, setShowOTP] = useState(false) 
  const [phoneError, setPhoneError] = useState(false)
  
  const auth = getAuth();


  function onCaptchaVerify() {
    window.recaptchaVerifier = new RecaptchaVerifier('otp-send-btn', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        btnOTPSend();
      }
    }, auth);
  }


  //BTN OTP SEND
  const btnOTPSend = () => {
    setLoading(true)
    onCaptchaVerify()

    const appVerifier = window.recaptchaVerifier;

    const formatPhone = '+' + phone
    console.log(formatPhone);

    if (phone.length === 0) {
      setPhoneError(true) 
      setLoading(false)
    } else {
      setPhoneError(false)
      signInWithPhoneNumber(auth, formatPhone, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          setLoading(false)
          setShowOTP(true)
          toast('OTP Send')
          // ...
        }).catch((error) => {
          console.log(error);
          setLoading(false)
          // Error; SMS not sent
          // ...
        });
    }

  }



  //BTN SUBMIT
  const btnSubmit = async () => {

    const code = otp;
    window.confirmationResult.confirm(code).then((result) => {
      // User signed in successfully.
      //const user = result.user;
      history('/')
      // ...
    }).catch((error) => {
      // User couldn't sign in (bad verification code?)
      // ...
    });

  }


  return (
    <div className='login'>
      <div className='box-login'>
        <Toaster />
        <h1 className='heading-login'>Login</h1>
        <div className='secure-icon'>
          <i class="fa-sharp fa-solid fa-shield-halved"></i>
        </div>

        {showOtp ?
          <>
            <label htmlFor="otp" className='otp-label'>Enter Your OTP</label>
            <OTPInput
              value={otp}
              onChange={setOTP}
              autoFocus
              OTPLength={6}
              otpType="number"
              disabled={false}
              className="otp-container"
            ></OTPInput>
            <div className='btn-container'>
              <button className='otp-verify-btn' onClick={btnSubmit}>
                {loading && <CgSpinner size={20} className='loading-spinner'></CgSpinner>}
                <span>Verify OTP</span>
              </button>
            </div>
          </>
          :
          <>
            <label htmlFor="number" className='number-label'>Enter Your Number</label>
            <PhoneInput
              country={'in'}
              value={phone}
              onChange={setPhone}
              className="phone-container"
              inputStyle={{ color: 'black' }}
              buttonStyle={{}}
              dropdownStyle={{ height: '50px' }}
              inputProps={{
                name: 'phone',
                required: true,
                autoFocus: true
              }}
            />
            {phoneError && <span className='error-phone-input'>Enter Phone Number</span>}
            <div className='btn-container'>
              <button className='otp-send-btn' onClick={btnOTPSend} id='otp-send-btn'>
                {loading && <CgSpinner size={20} className='loading-spinner'></CgSpinner>}
                <span>Send OTP</span>
              </button>
            </div>
          </>
        }

      </div>
    </div>
  )
}

export default Login