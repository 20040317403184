import React, { useState, useContext, useEffect } from 'react'
import './Search.css'
import Header from '../../components/Header/Header'
import { getDocs, getFirestore, collection, query } from "firebase/firestore";
import { FirebaseContext } from '../../Context'
import { useNavigate } from 'react-router-dom'
import Fuse from 'fuse.js';



function Search() {
    const [postData, setPostData] = useState([]);
    const [sellerData, setSellerData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [error, setError] = useState(false);
    const { firebase } = useContext(FirebaseContext);
    const [searchProducts, setSearchProducts] = useState([]);
    const db = getFirestore(firebase);

    console.log(searchTerm);

    const navigate = useNavigate()

    useEffect(() => {

        async function fetchPostData() {
            try {

                // Use the twoDaysAgo and currentDate in the Firestore query
                const q = await query(collection(db, "posts"));

                try {
                    const querySnapshot = await getDocs(q);
                    const allPosts = [];

                    querySnapshot.forEach((doc) => {
                        // doc.data() is never undefined for query doc snapshots
                        //console.log(doc.id, " => ", doc.data());
                        allPosts.push({
                            ...doc.data(),
                            id: doc.id
                        });
                    });

                    setPostData(allPosts);
                    console.log(allPosts);
                } catch (error) {
                    console.log("Error getting posts: ", error);
                }
            } catch (e) {
                console.log(e);
            }
        }


        async function fetchSellerData() {
            try {
                await getDocs(collection(db, "sellers")).then((snapshot) => {
                    const allSellers = snapshot.docs.map((sellers) => {
                        return {
                            ...sellers.data(),
                            id: sellers.id
                        }
                    })
                    setSellerData(allSellers)
                    //console.log(allSellers);
                })
            } catch (e) {
                console.log(e);
            }
        }

        fetchPostData();
        fetchSellerData();
    }, [db])


    //Fuse Instance
    const fuse = new Fuse(postData, {
        keys: ['productName', 'model'], // Fields to search in
        includeScore: true, // Include search score
        threshold: 0.3, // Minimum score to consider a match
    });


    //Search Algorithm
    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);

        if (searchTerm) {
            const searchResults = fuse.search(searchTerm);
            const formattedResults = searchResults.map((result) => ({
                ...result.item,
                id: result.item.id
            }));

            if (formattedResults.length > 0) {
                setSearchProducts(formattedResults);
                setError(false); // Reset error if there are search results
            } else {
                setSearchProducts([]);
                setError(true); // Set error if no search results found
            }
        } else {
            setSearchProducts([]);
            setError(false); // Reset error if search term is empty
        }
    };


    //onClick on the Post
    const handleClick = (post) => {
        const postId = post.id;
        navigate(`/view-post/${postId}`);
    };

    return (
        <div className='Search'>
            <Header />
            <div className='search-box'>
                <input
                    type="text"
                    name=""
                    placeholder='Search "Maruti 800"'
                    value={searchTerm}
                    onChange={handleSearch}
                />
                <i class="fa-sharp fa-solid fa-magnifying-glass"></i>
            </div>
            {/* <p className='latest-heading'>Latest</p> */}
            {error
                && <div className='search-error'>
                    <p className='search-error-message center'>Not Found!</p>
                </div>
            }
            <div className='latest-post'>

                {searchProducts && searchProducts.map((post) => {
                    var firestoreDate = post.date;
                    var jsDate = firestoreDate.toDate();
                    var options = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
                    var formattedDate = jsDate.toLocaleDateString('en-US', options);
                    //console.log(formattedDate);
                    var currentDate = new Date();
                    var isToday = jsDate.toDateString() === currentDate.toDateString();
                    var isYesterday = new Date(jsDate.getFullYear(), jsDate.getMonth(), jsDate.getDate() + 1).toDateString() === currentDate.toDateString();
                    var displayDate = isToday ? 'Today' : (isYesterday ? 'Yesterday' : formattedDate);
                    return (
                        <div class="card"
                            onClick={() => handleClick(post)}>
                            <img
                                className={`latest-img ${post.status === false ? 'height-90' : 'height-110'}`}
                                src={post.images}
                                alt="Avatar"
                            />
                            {post.status === false ? <span className='item-sold'>Item Sold</span> : ''}
                            <div class="container">
                                <h2>₹ {post.price}</h2>
                                <hr />
                                <h4><b>{post.productName}</b></h4>
                                <p>{post.model}</p>
                                <div className='seller-date'>
                                    <p>{displayDate}</p>
                                    {sellerData.map((seller) => {
                                        return (
                                            <p>{post.userId === seller.userId ? seller.sellerName : ''}</p>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    )
                })}

            </div>
        </div>
    )
}

export default Search