import React from 'react'
import './AdminHeader.css'

function AdminHeader() {
    return (
        <div className='AdminHeader'>
            <div className='container'>
                <nav>
                    <img src="\images\logo.png" alt="" className='logo' />
                    <ul id='sidemenu'>
                        <i class="fa-solid fa-xmark" onClick={() => {
                            var sideMenu = document.getElementById("sidemenu")
                            sideMenu.style.right = "-200px"
                        }}></i>
                    </ul>
                    <i class="fa-solid fa-bars" onClick={() => {
                        var sideMenu = document.getElementById("sidemenu")
                        sideMenu.style.right = "0"
                    }}></i>
                </nav>
            </div>
        </div>
    )
}

export default AdminHeader