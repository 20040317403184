import React, { useEffect, useState } from 'react';
import './Footer.css';

function Footer() {
    const [isPageLoaded, setPageLoaded] = useState(false);

    useEffect(() => {
        // Simulating the delay for fetching data from Firebase
        setTimeout(() => {
            setPageLoaded(true);
        }, 5000); // Adjust the delay as needed
    }, []);

    return (
        <>
            {isPageLoaded && (
                <div className='footer-body'>
                    <div className='footer'>
                        <div className='details-row'>
                            <div className='details-coloumn'>
                                <p>SecondDrive</p>
                                <p>About</p>
                                <p>Contact</p>
                            </div>
                            <div className='details-coloumn'>
                                <a href="/search">Search</a>
                                <a href="/all-post">All Products</a>
                                <a href="/latest">Latest Products</a>
                            </div>
                        </div>
                        <div className='copyright'>
                            <p>Copyright © 2023 onFocus Technologies | All rights reserved</p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Footer;