import React, { useState, useContext } from 'react'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom'
import { FirebaseContext } from '../Context'
import './AdminLogin.css';


function AdminLogin() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate()
    const { firebase } = useContext(FirebaseContext)
    const auth = getAuth(firebase);


    const handleAdminLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                console.log('Success');
                navigate('/admin')
            })
            .catch((error) => {
                console.error('Error signing in:', error);
            });
    };

    return (
        <div className='AdminLogin'>
            <div className='login-container'>
                <div className='admin-login-heading'>
                    <h3>ADMIN LOGIN</h3>
                </div>
                <form onSubmit={handleAdminLogin}>
                    <div className='admin-login-inputs'>
                        <input
                            type="email"
                            name="username"
                            placeholder='Email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <input
                            type="password"
                            name="password"
                            placeholder='Password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className='admin-login-button'>
                        <button>Login</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AdminLogin