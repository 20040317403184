import React from 'react'
import './AllSellers.css'



function AllSellers(props) {
    const allSellers = props.allSellers;

    return (
        <div className='AllSellers'>
            <div className='admin-all-sellers'>
                <h2>All Sellers</h2>
                <table id="sellers">
                    <tr>
                        <th>Name</th>
                        <th>Place</th>
                        <th>WhatsApp</th>
                    </tr>
                    {allSellers.map((seller) => {
                        return (
                            <tr>
                                <td>{seller.sellerName}</td>
                                <td>{seller.place}</td>
                                <td>{seller.whatsappNumber}</td>
                            </tr>
                        )
                    })}
                </table>
            </div>
        </div>
    )
}

export default AllSellers