import React from 'react'
import './AllUsers.css'

function AllUsers(props) {
    const allUsers = props.allUsers;
    console.log(allUsers);

    return (
        <div className='AllUsers'>
            <div className='admin-all-users'>
                <h2>All Users</h2>
                <table id="users">
                    <tr>
                        <th>Name</th>
                        <th>Contact</th>
                        <th>Email</th>
                    </tr>
                    {allUsers.map((users) => {
                        return (
                            <tr>
                                <td>{users.fname} {users.lname}</td>
                                <td>{users.phone}</td>
                                <td>{users.email}</td>
                            </tr>
                        )
                    })}
                </table>
            </div>
        </div>
    )
}

export default AllUsers