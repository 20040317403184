import './App.css';
import React, { useContext, useEffect } from 'react';
import Home from './Pages/Home/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Login from './Pages/Login/Login';
import ViewPost from './Pages/ViewPost/ViewPost';
import Profile from './Pages/Profile/Profile';
import Latest from './Pages/Latest/Latest';
import AllPost from './Pages/AllPost/AllPost';
import Admin from './Admin/Admin';
import { AuthContext, FirebaseContext } from './Context'
import { getAuth, onAuthStateChanged } from "firebase/auth";
import PrivateRoute from './PrivateRoute';
import AdminLogin from './Admin/AdminLogin';
import Search from './Pages/Search/Search';


function App() {
  const { setUser } = useContext(AuthContext);
  const { user } = useContext(AuthContext);
  const { firebase } = useContext(FirebaseContext);
  const auth = getAuth(firebase);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setUser(user);
      } else {
        // User is signed out
        setUser(null);
      }
    });
  }, [auth, setUser]);

  return (
    <div className='App'>
      <Router>
        <Routes>
          <React.Fragment>
            <Route element={<Home />} path='/' />
            <Route element={<Login />} path='/login' />
            <Route element={<ViewPost />} path='/view-post/:postId' />
            <Route element={<PrivateRoute isUserLogged={user} />} >
              <Route element={<Profile />} path='/account' />
            </Route>
            <Route element={<Latest />} path='/latest' />
            <Route element={<AllPost />} path='/all-post' />
            <Route element={<Search />} path='/search' />
            <Route element={<AdminLogin />} path='/admin-login' />
            <Route element={<Admin />} path='/admin' />
          </React.Fragment>
        </Routes>
      </Router>
    </div>
  )
}

export default App;
