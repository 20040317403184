import React, { useContext, useEffect, useState } from 'react'
import './Banner.css'
import { FirebaseContext } from '../../Context'
import { doc, getDoc, getFirestore } from "firebase/firestore";


function Banner() {
  const { firebase } = useContext(FirebaseContext);
  const [largeBanner, setLargeBanner] = useState('');
  const [smallBanner, setSmallBanner] = useState('');
  const db = getFirestore(firebase);

  useEffect(() => {
    async function fetchLargeDeviceBanner() {
      try {
        const docRef = doc(db, "banner", "large_device");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          //console.log("Document data:", docSnap.data().image);
          setLargeBanner(docSnap.data().image);
        } else {
          // docSnap.data() will be undefined in this case
          //console.log("No such document!");
        }
      } catch (error) {
        console.log(error);
      }
    }


    async function fetchSmallDeviceBanner() {
      try {
        const docRef = doc(db, "banner", "small_device");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          //console.log("Document data:", docSnap.data().image);
          setSmallBanner(docSnap.data().image);
        } else {
          // docSnap.data() will be undefined in this case
          //console.log("No such document!");
        }
      } catch (error) {
        console.log(error);
      }
    }

    fetchLargeDeviceBanner();
    fetchSmallDeviceBanner();
  }, [db])

  return (
    <div className='Banner'>
      <img className='banner-img' src="images\banner.jpg" alt="" /> 
      <img className='banner-mobile' src="images\banner-mobile.jpg" alt="" />
    </div>
  )
}

export default Banner