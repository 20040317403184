import React from 'react'
import './Admin.css'
import AdminHeader from '../components/AdminHeader/AdminHeader'
import AllUsers from './components/AllUsers/AllUsers'
import NewUsers from './components/NewUsers/NewUsers'
import BlacklistedUsers from './components/BlacklistedUsers/BlacklistedUsers'
import AllSellers from './components/AllSellers/AllSellers'
import NewSellers from './components/NewSellers/NewSellers'
import BlacklistedSellers from './components/BlacklistedSellers/BlacklistedSellers'
import Subscription from './components/Subscription/Subscription'
import AllPost from './components/All Post/AllPost'
import NewPost from './components/New Post/NewPost'
import SoldPost from './components/SoldPost/SoldPost'
import LargeDevice from './components/LargeDevice/LargeDevice'
import SmallDevice from './components/SmallDevice/SmallDevice'
import { useState, useContext, useEffect } from 'react'
import { FirebaseContext } from '../../src/Context';
import { getDocs, getFirestore, collection, query, where, orderBy } from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom'


function Admin() {
  const [component, setComponent] = useState("")
  const { firebase } = useContext(FirebaseContext);
  const db = getFirestore(firebase);

  const [allUsers, setAllUsers] = useState([])
  const [newUsers, setNewUsers] = useState([])
  const [blacklistedUsers, setBlacklistedUsers] = useState(null)

  const [allSellers, setAllSellers] = useState([])
  const [newSellers, setNewSellers] = useState([])
  const [blacklistedSellers, setBlacklistedSellers] = useState(null)

  const [allPosts, setAllPosts] = useState([])
  const [newPosts, setNewPosts] = useState([])
  const [soldPosts, setSoldPosts] = useState([])

  setBlacklistedSellers(null)
  setBlacklistedUsers(null)

  const navigate = useNavigate()

  useEffect(() => {
    async function fetchUserData() {
      try {
        const twoWeekAgo = new Date();
        twoWeekAgo.setDate(twoWeekAgo.getDate() - 15);
        const currentDate = new Date();

        const queryAllUsers = query(collection(db, "users"));
        const queryNewUsers = query(collection(db, "users"), where("date", ">=", twoWeekAgo), where("date", "<=", currentDate));
        //const queryBlaclistedUsers = query(collection(db, "users"));

        const snapshotAllUsers = await getDocs(queryAllUsers);
        const allUSERS = [];
        snapshotAllUsers.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id, " => ", doc.data());
          allUSERS.push({
            ...doc.data(),
            id: doc.id
          })
        });
        setAllUsers(allUSERS);
        //console.log(allUSERS);

        const snapshotNewUsers = await getDocs(queryNewUsers);
        const newUSERS = [];
        snapshotNewUsers.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id, " => ", doc.data());
          newUSERS.push({
            ...doc.data(),
            id: doc.id
          })
        });
        setNewUsers(newUSERS);
        //console.log(newUSERS);



      } catch (error) {
        //console.log(error);
      }
    }


    async function fetchSellerData() {
      try {
        const twoWeekAgo = new Date();
        twoWeekAgo.setDate(twoWeekAgo.getDate() - 15);
        const currentDate = new Date();

        const queryAllSellers = query(collection(db, "sellers"));
        const queryNewSellers = query(collection(db, "sellers"), where("date", ">=", twoWeekAgo), where("date", "<=", currentDate));
        //const queryBlaclistedUsers = query(collection(db, "users"));

        const snapshotAllSellers = await getDocs(queryAllSellers);
        const allSELLERS = [];
        snapshotAllSellers.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id, " => ", doc.data());
          allSELLERS.push({
            ...doc.data(),
            id: doc.id
          })
        });
        setAllSellers(allSELLERS);
        //console.log(allSELLERS);

        const snapshotNewSellers = await getDocs(queryNewSellers);
        const newSELLERS = [];
        snapshotNewSellers.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id, " => ", doc.data());
          newSELLERS.push({
            ...doc.data(),
            id: doc.id
          })
        });
        setNewSellers(newSELLERS);
        //console.log(newSELLERS);



      } catch (error) {

      }
    }


    async function fetchPostData() {
      try {
        const twodaysAgo = new Date();
        twodaysAgo.setDate(twodaysAgo.getDate() - 2);
        const currentDate = new Date();

        const queryAllPost = query(collection(db, "posts"), orderBy("date", "desc"));
        const queryNewPost = query(collection(db, "posts"), where("date", ">=", twodaysAgo), where("date", "<=", currentDate), orderBy("date", "desc"));
        const querySoldPost = query(collection(db, "posts"), where("status", "==", false));

        const snapshotAllPost = await getDocs(queryAllPost);
        const allPOST = [];
        snapshotAllPost.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id, " => ", doc.data());
          allPOST.push({
            ...doc.data(),
            id: doc.id
          })
        });
        setAllPosts(allPOST);
        console.log(allPOST);

        const snapshotNewPost = await getDocs(queryNewPost);
        const newPOST = [];
        snapshotNewPost.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id, " => ", doc.data());
          newPOST.push({
            ...doc.data(),
            id: doc.id
          })
        });
        setNewPosts(newPOST);
        console.log(newPOST);


        const snapshotSoldPost = await getDocs(querySoldPost);
        const soldPOST = [];
        snapshotSoldPost.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id, " => ", doc.data());
          soldPOST.push({
            ...doc.data(),
            id: doc.id
          })
        });
        setSoldPosts(soldPOST);
        console.log(soldPOST);


      } catch (error) {

      }
    }

    fetchUserData();
    fetchSellerData();
    fetchPostData();


  }, [db])

  const handleAdminLogout = (() => {
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
      navigate('/admin-login') 
    }).catch((error) => {
      // An error happened.
    });
  })



  return (
    <div className='Admin'>
      <AdminHeader />
      <div className='admin-page'>
        <div className='admin-side-menu'>
          {/* User Details */}
          <div className='user-details'>
            <div className='user-sub-heading'>
              <i class="fa-solid fa-users"></i>
              <h2>Users</h2>
            </div>
            <div className='user-items'>
              <div onClick={() => setComponent("all-users")} className='admin-div-button'>All Users</div>
              <div onClick={() => setComponent("new-users")} className='admin-div-button'>New Users</div>
              <div onClick={() => setComponent("blacklisted-users")} className='admin-div-button'>Blacklisted</div>
            </div>
          </div>
          <hr />

          {/* Seller Details */}
          <div className='seller-details'>
            <div className='seller-sub-heading'>
              <i class="fa-solid fa-car"></i>
              <h2>Sellers</h2>
            </div>
            <div className='seller-items'>
              <div onClick={() => setComponent("all-sellers")} className='admin-div-button'>All Sellers</div>
              <div onClick={() => setComponent("new-sellers")} className='admin-div-button'>New Sellers</div>
              <div onClick={() => setComponent("blacklisted-sellers")} className='admin-div-button'>Blacklisted</div>
              <div onClick={() => setComponent("subscription")} className='admin-div-button'>Subscription</div>
            </div>
          </div>
          <hr />



          {/* Seller Posts */}
          <div className='seller-post-details'>
            <div className='seller-post-sub-heading'>
              <i class="fa-solid fa-plus"></i>
              <h2>Post</h2>
            </div>
            <div className='seller-post-items'>
              <div onClick={() => setComponent("all-post")} className='admin-div-button'>All Post</div>
              <div onClick={() => setComponent("new-post")} className='admin-div-button'>New Post</div>
              <div onClick={() => setComponent("sold-post")} className='admin-div-button'>Sold</div>
            </div>
          </div>
          <hr />

          {/* Banner */}
          <div className='banner-details'>
            <div className='banner-sub-heading'>
              <i class="fa-solid fa-image"></i>
              <h2>Banner</h2>
            </div>
            <div className='banner-items'>
              <div onClick={() => setComponent("large-device")} className='admin-div-button'>Large Device</div>
              <div onClick={() => setComponent("small-device")} className='admin-div-button'>Small Device</div>
            </div>
          </div>
          <hr />

          {/* Logout */}
          <div className='admin-logout'>
            <div className='admin-logout-sub-heading'>
              <i class="fa-solid fa-power-off"></i>
              <h2 onClick={handleAdminLogout}>Logout</h2>
            </div>
          </div>


        </div>


        {/* Component  */}
        {component === "all-users" && <AllUsers allUsers={allUsers} />}
        {component === "new-users" && <NewUsers newUsers={newUsers} />}
        {component === "blacklisted-users" && <BlacklistedUsers blacklistedUsers={blacklistedUsers} />}


        {component === "all-sellers" && <AllSellers allSellers={allSellers} />}
        {component === "new-sellers" && <NewSellers newSellers={newSellers} />}
        {component === "blacklisted-sellers" && <BlacklistedSellers blacklistedSellers={blacklistedSellers} />}
        {component === "subscription" && <Subscription />}


        {component === "all-post" && <AllPost allPosts={allPosts} allSellers={allSellers} />}
        {component === "new-post" && <NewPost newPosts={newPosts} allSellers={allSellers} />}
        {component === "sold-post" && <SoldPost soldPosts={soldPosts} allSellers={allSellers} />}

        {component === "large-device" && <LargeDevice />}
        {component === "small-device" && <SmallDevice />}
      </div>
    </div>
  )
}

export default Admin