import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import './PrivateRoute.css'


const PrivateRoute = ({ isUserLogged }) => {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const checkUserAuthentication = async () => {
            await new Promise((resolve) => setTimeout(resolve, 1000));

            setIsLoading(false);
        };
        checkUserAuthentication();
    }, []);

    if (isLoading) {
        // Render a loading spinner or temporary UI
        return <Box sx={{ display: 'flex' }}>
            <CircularProgress className='circular-progress' />
        </Box>
    }

    return isUserLogged ? <Outlet /> : navigate('/login');
};

export default PrivateRoute;