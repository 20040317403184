import React from 'react'
import './Subscription.css'



function Subscription() {
    return (
        <div className='Subscription'>
            <div className='Subscription'>
                <div className='admin-subscription'>
                    <h2>Subscription</h2>
                    <table id="sellers">
                        <tr>
                            <th>Seller Name</th>
                            <th>Current Subscription</th>
                            <th>Pending</th>
                            <th>Request</th>
                        </tr>
                        <tr>
                            <td>Alfreds Futterkiste</td>
                            <td>Maria Anders</td>
                            <td>Germany</td>
                            <td>Link</td>
                        </tr>
                        <tr>
                            <td>Berglunds snabbköp</td>
                            <td>Christina Berglund</td>
                            <td>Sweden</td>
                            <td>Link</td>
                        </tr>
                        <tr>
                            <td>Centro comercial Moctezuma</td>
                            <td>Francisco Chang</td>
                            <td>Mexico</td>
                            <td>Link</td>
                        </tr>
                        <tr>
                            <td>Ernst Handel</td>
                            <td>Roland Mendel</td>
                            <td>Austria</td>
                            <td>Link</td>
                        </tr>
                        <tr>
                            <td>Island Trading</td>
                            <td>Helen Bennett</td>
                            <td>UK</td>
                            <td>Link</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Subscription