import React from 'react'
import './Post.css'
import { useState, useEffect, useContext } from 'react'
import { FirebaseContext } from '../../Context';
import { getDocs, getFirestore, collection, query, where, orderBy } from "firebase/firestore";
import { useNavigate } from 'react-router-dom'



function Post() {
    const [latestPost, setLatestPost] = useState([])
    const [allPost, setAllPost] = useState([])
    const [sellerData, setSellerData] = useState([])
    const { firebase } = useContext(FirebaseContext);
    const db = getFirestore(firebase);
    const navigate = useNavigate()

    useEffect(() => {
        async function fetchPostData() {
            try {
                // Calculate the date two days ago
                const twoDaysAgo = new Date();
                twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);

                // Create a new date object for the current date
                const currentDate = new Date();

                // Use the twoDaysAgo and currentDate in the Firestore query
                //For latest post
                const q = await query(collection(db, "posts"), where("date", ">=", twoDaysAgo), where("date", "<=", currentDate), orderBy("date", "desc"));
                const q2 = await query(collection(db, "posts"), where("status", "==", true));

                try {
                    //For latest post
                    const latest = await getDocs(q);
                    //For all post
                    const all = await getDocs(q2);

                    const latestPosts = []
                    const allPosts = [];

                    //For latest post
                    latest.forEach((doc) => {
                        // doc.data() is never undefined for query doc snapshots
                        //console.log(doc.id, " => ", doc.data());
                        latestPosts.push({
                            ...doc.data(),
                            id: doc.id
                        });
                    });

                    all.forEach((doc) => {
                        // doc.data() is never undefined for query doc snapshots
                        //console.log(doc.id, " => ", doc.data());
                        allPosts.push({
                            ...doc.data(),
                            id: doc.id
                        });
                    });

                    setLatestPost(latestPosts)
                    setAllPost(allPosts);
                    //console.log(latestPosts);
                    //console.log(allPosts);
                } catch (error) {
                    console.log("Error getting posts: ", error);
                }
            } catch (e) {
                console.log(e);
            }
        }


        async function fetchSellerData() {
            try {
                await getDocs(collection(db, "sellers")).then((snapshot) => {
                    const allSellers = snapshot.docs.map((sellers) => {
                        return {
                            ...sellers.data(),
                            id: sellers.id
                        }
                    })
                    setSellerData(allSellers)
                    //console.log(allSellers);
                })
            } catch (e) {
                console.log(e);
            }
        }


        fetchPostData();
        fetchSellerData();
    }, [db])


    return (
        <div className='Post'>

            {latestPost.length > 0 ? <h1 className='sub-heading-latest'>Latest ADs</h1> : ""}
            {latestPost.length > 0 ? <a href="/latest" className='view-all-link'><span className='view-all'>View All</span></a> : ""}

            {/* Latest Post */}
            <div className='latest'>

                {latestPost.slice(0, 5).map((post) => {
                    var firestoreDate = post.date;
                    var jsDate = firestoreDate.toDate();
                    var options = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
                    var formattedDate = jsDate.toLocaleDateString('en-US', options);
                    //console.log(formattedDate);
                    var currentDate = new Date();
                    var isToday = jsDate.toDateString() === currentDate.toDateString();
                    var isYesterday = new Date(jsDate.getFullYear(), jsDate.getMonth(), jsDate.getDate() + 1).toDateString() === currentDate.toDateString();
                    var displayDate = isToday ? 'Today' : (isYesterday ? 'Yesterday' : formattedDate);
                    return (
                        <div class="card"
                            onClick={(() => {
                                const postId = post.id;
                                navigate(`/view-post/${postId}`);
                            })}
                        >
                            <div className='image-container'>
                                <img
                                    className={`latest-img ${post.status === false ? 'height-90' : 'height-110'}`}
                                    src={post.images}
                                    alt="Avatar"
                                />
                                {post.status === false ? <span className='item-sold'>Item Sold</span> : ''}
                            </div>
                            <div class="container">
                                <h2>₹ {post.price}</h2>
                                <hr />
                                <h4><b>{post.productName}</b></h4>
                                <p>{post.model}</p>
                                <div className='seller-date'>
                                    <p>{displayDate}</p>
                                    {sellerData.map((seller) => {
                                        return (
                                            <p>{post.userId === seller.userId ? seller.sellerName : ''}</p>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    )
                })}



            </div>


            {/* All Post */}
            {allPost && <h1 className='sub-heading-allpost'>All ADs</h1>}
            {allPost && <a href="/all-post" className='view-all-link'><span className='view-all'>View All</span></a>}
            <div className='all-post'>

                {allPost.slice(0, 5).map((post) => {
                    var firestoreDate = post.date;
                    var jsDate = firestoreDate.toDate();
                    var options = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
                    var formattedDate = jsDate.toLocaleDateString('en-US', options);
                    //console.log(formattedDate);
                    var currentDate = new Date();
                    var isToday = jsDate.toDateString() === currentDate.toDateString();
                    var isYesterday = new Date(jsDate.getFullYear(), jsDate.getMonth(), jsDate.getDate() + 1).toDateString() === currentDate.toDateString();
                    var displayDate = isToday ? 'Today' : (isYesterday ? 'Yesterday' : formattedDate);
                    return (
                        <div class="card"
                            onClick={(() => {
                                const postId = post.id;
                                navigate(`/view-post/${postId}`);
                            })}
                        >
                            <div className='image-container'>
                                <img
                                    className={`latest-img ${post.status === false ? 'height-90' : 'height-110'}`}
                                    src={post.images}
                                    alt="Avatar"
                                />
                                {post.status === false ? <span className='item-sold'>Item Sold</span> : ''}
                            </div>
                            <div class="container">
                                <h2>₹ {post.price}</h2>
                                <hr />
                                <h4><b>{post.productName}</b></h4>
                                <p>{post.model}</p>
                                <div className='seller-date'>
                                    <p>{displayDate}</p>
                                    {sellerData.map((seller) => {
                                        return (
                                            <p>{post.userId === seller.userId ? seller.sellerName : ''}</p>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    )
                })}


            </div>
        </div>
    )
}

export default Post